
import { defineComponent } from 'vue'

import iStatusInProgress from '@/assets/icons/statuses/StatusInProgress.svg'
import iStatusCompleted from '@/assets/icons/statuses/StatusCompleted.svg'
import iStatusCanceled from '@/assets/icons/statuses/StatusCanceled.svg'

export default defineComponent({
    components: {
        'icon-status-in-progress': iStatusInProgress,
        'icon-status-completed': iStatusCompleted,
        'icon-status-canceled': iStatusCanceled,
    },
    props: {
        status: {
            type: String,
            default: null,
        },
        statusNumber: {
            type: Number,
            default: 0,
        },
        wasReturned: {
            type: Number,
            default: 0,
        },
    },
})
